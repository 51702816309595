<template>
  <div class="message">
    <div class="navTitle">消息</div>
    <div class="card" v-show="loanList.length > 0">
      <div class="card-title flex">
        <img src="../assets/img/msg1.png" alt="">
        <p class="titleText">融资贷款</p>
        <!-- <p class="titleDay">{{ loanList[0] ? loanList[0].createTime.slice(0,10).replace(/-/g,"/") : ''}}</p>
        <span>{{loanList.length}}</span> -->
      </div>
      <router-link class="card-content2 flex" v-for="item in loanList" :key="item.id" :to="'/messageContent/'+item.id">
          <div class="p"> 
            <!-- <p class="p1">{{item.title}}</p> -->
            <p class="p2">{{item.title}}</p>
          </div> 
          <p class="pdate">{{ item.createTime.slice(0,10).replace(/-/g,"/")}}<span v-show="item.readStatus!='1'" class="pdot"></span></p> 
      </router-link>
    </div>
    <div class="card" v-show="productRecomList.length > 0">
      <div class="card-title flex">
        <img src="../assets/img/msg2.png" alt="">
        <p class="titleText">产品推荐</p>
        <!-- <p class="titleDay">{{ productRecomList[0] ? productRecomList[0].createTime.slice(0,10).replace(/-/g,"/") : ''}}</p>
        <span>{{productRecomList.length}}</span> -->
      </div>
      <router-link  class="card-content2 flex" v-for="item in productRecomList" :key="item.id" :to="'/messageContent/'+item.id">
          <div class="p">
            <!-- <p class="p1">{{item.title}}</p> -->
            <p class="p2">{{item.title}}</p>
            <p class="pdate">{{item.createTime}}<span v-show="item.readStatus!='1'" class="pdot"></span></p>
          </div>
          <!-- <img src="../assets/img/yhcp2.png" alt=""> -->
          <img v-if="item.imageUrl" :src="store.state.imgURL + item.imageUrl" alt="">   
      </router-link>
    </div>
    <div class="card" v-show="policyList.length > 0">
      <div class="card-title flex">
        <img src="../assets/img/msg3.png" alt="">
        <p class="titleText">系统消息</p>
        <!-- <p class="titleDay">{{ policyList[0] ? policyList[0].createTime.slice(0,10).replace(/-/g,"/") : ''}}</p>
        <span>{{policyList.length}}</span> -->
      </div>  
      <router-link  class="card-content2 flex" v-for="item in policyList" :key="item.id" :to="'/messageContent/'+item.id">
          <div class="p">
            
            <!-- <p class="p1">{{item.title}}</p> -->
            <p class="p2"><span v-show="item.readStatus!='1'" class="pdot"></span>{{item.title}}</p>
          </div> 
          <p class="pdate">{{ item.createTime.slice(0,10).replace(/-/g,"/")}}</p>
      </router-link>

    </div>
    <div class="over">{{ loanList.length + policyList.length + productRecomList.length > 0 ? '— 到底啦 —' : '— 还没有接收到消息 —'}}</div>
    <div style="height: 15vw; width:100%"></div>
    <TabBar v-if="!store.state.isiOS" nav="message"/>
  </div>
</template>
<script>
import { apiGetDatas } from '../api/axios';
import TabBar from '../components/TabBar.vue';
import { Toast } from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive,ref,toRefs,toRaw,onUnmounted } from 'vue';
export default {
  setup() {
    let store = useStore();
    if(store.state.isiOS) {
      try {
        window.webkit.messageHandlers.showBar.postMessage(null);
      } catch (e) {
        console.log(e);
      }
    }
    let router = useRouter();
    const state = reactive({
        loanList: [],
        policyList: [],
        productRecomList: []
    });
    // localStorage.clear()
    let loacalMessage = JSON.parse(localStorage.getItem("gxrMessage"));
    // console.log(loacalMessage,55);
    
    const noLocal = () => {
      apiGetDatas({}).then(res => {                 
        if(res.code == '1') {
           state.loanList = res.info.loanList;
           state.policyList = res.info.policyList;
           state.productRecomList = res.info.productRecomList;
           localStorage.setItem("gxrMessage", JSON.stringify(res.info));           
           store.dispatch('setMsg',res.info);

         }
         else {
           Toast.fail(res.msg);
         }
      });
    }
    const haveLocal = () => {
      apiGetDatas({}).then(res => {
        if(res.code == '1') {
          res.info.loanList.forEach((item) => {
              var filter = loacalMessage.loanList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.loanList.push(filter[0]);
              }else {
                  state.loanList.push(item);
              }
           })
          //  state.loanList = res.info.loanList;
           res.info.policyList.forEach((item) => {
              var filter = loacalMessage.policyList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.policyList.push(filter[0]);
              }else {
                  state.policyList.push(item);
              }
           })
          //  state.productRecomList = res.info.productRecomList;
          res.info.productRecomList.forEach((item) => {
              var filter = loacalMessage.productRecomList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.productRecomList.push(filter[0]);
              }else {
                  state.productRecomList.push(item);
              }
           })

           localStorage.setItem("gxrMessage", JSON.stringify(toRaw(state)));
           store.dispatch('setMsg',toRaw(state));
           
         }
         else {
           Toast.fail(res.msg);
         }
        });
    }
    if(loacalMessage) { haveLocal()}else {noLocal()}

    //卸载
    onUnmounted(()=>{
      if(store.state.isiOS) {
        try {
          window.webkit.messageHandlers.hideBar.postMessage(null);
        } catch (e) {
          console.log(e);
        }
      }
    })
    return {
      store,
      ...toRefs(state)
    }
  },
  components: {
    TabBar
  }
}
</script>
<style lang="less" scoped>
.message {
  font-size: 4vw;
  padding-top: 12.5vw;
  .navTitle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    height: 12.5vw;
    text-align: center;
    line-height: 12.5vw;
    color: #fff;
    img {
        height: 4vw;
        position: absolute;
        left: 4vw;
        top: 4.3vw;
    }
  }
  .card {
    display: block;
    color: #333;
    margin: 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 4vw;
    .card-title {
      margin-bottom: 4vw;
      img {
        width: 7.8vw;
      }
      .titleText {
        font-size: 4.8vw;
        font-weight: 600;
        flex: 1;
        padding: 0 2vw;
      }
      .titleDay {
        font-size: 3.2vw;
        color: #666666;
      }
      span {
        color: #fff;
        background-color: #ff6600;
        font-size: 3.5vw;
        padding: 0 1vw;
        border-radius: 100%;
        margin-left: 1vw;
        text-align: center;
        min-width: 2.5vw;
      }
    }
    .card-content {
      color: #666666;
      margin: 4vw 0;
    }
    .card-content2 {
      margin-bottom: 2vw;
      padding-bottom: 2vw;
      border-bottom: 0.15vw solid #eee;
      .p {
        flex: 1;
        .p1 {
          font-size: 4.5vw;
          font-weight: 600;
          margin-bottom: 1vw;
        }
        .p2 {
          display: inline-block;
          width: 55vw;
          padding-left: 4vw;
          color: #333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          .pdot {
            display: inline-block;
            width: 2vw;
            height: 2vw;
            border-radius: 50%;
            background-color: rgba(245, 118, 33, 0.8);
            position: absolute;
            left: 0;
            top: 1.5vw;
          }
        }
      }
      .pdate {
        font-size: 3.2vw;
        color: #666666;
        position: relative;
        display: inline-block;
        
      }
      img {
        width: 28.8vw;
        height: auto;
        border-radius: 1.5vw;
      }
    }
    .card-content2:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
  }
  .over {
    text-align: center;
    color: #999;
    margin-top: 4vw;
  }
}
</style>
